import React, { useEffect, useState } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import PrivateRoute from "./Authentication/PrivateRoute";
import "./index.scss";
import Loader from "./shade/Loaders/Loaders";
import { useSelector } from "react-redux";





// Main Pages
const App = React.lazy(() =>
  import("./App")
);
const Auth = React.lazy(() =>
  import("./Authentication/auth")
);

// Common Pages
const CustomPages = React.lazy(() =>
  import("../src/shade/layouts/custompages")
);
const Error404 = React.lazy(() =>
  import("./components/Pages/Authentication/404Error/404Error")
);

// Auth Pages
const AuthLogin = React.lazy(() =>
  import("./Authentication/Login")
);
const CreatePassword = React.lazy(() =>
  import("./components/User/UserManagement/CreatePassword")
);
const ForgotPassword = React.lazy(() =>
  import("./components/User/UserManagement/ForgotPassword")
);
const ForcePasswordChange = React.lazy(() =>
  import("./Authentication/ForcePasswordChange")
);

// Payment Gateway
const PaymentPage = React.lazy(() =>
  import("./components/PaymentGateway/PaymentPage")
);
const OnlinePaymentReceipt = React.lazy(() =>
  import("./components/PaymentGateway/OnlinePaymentReceipt")
);
const OnlineGPPaymentReceipt = React.lazy(() =>
  import("./components/PaymentGateway/OnlineGPPaymentReceipt")
);
const ECMSPaymentPage = React.lazy(() =>
  import("./components/PaymentGateway/ECMSPaymentPage")
);

// Payment Redirect
const PaymentRedirectSuccess = React.lazy(() =>
  import("./components/PaymentRedirect/PaymentRedirectSuccess")
);
const PaymentRedirectFail = React.lazy(() =>
  import("./components/PaymentRedirect/PaymentRedirectFail")
);
const PaymentRedirectCancel = React.lazy(() =>
  import("./components/PaymentRedirect/PaymentRedirectCancel")
);

// Dashboard
const Dashboard = React.lazy(() =>
  import("./components/Dashboard/Dashboard")
);
const InboxList = React.lazy(() =>
  import("./components/Inbox/InboxList")
);
const InboxDetails = React.lazy(() =>
  import("./components/Inbox/InboxDetails")
);
const Profile = React.lazy(() =>
  import("./components/Pages/Profile/Profile")
);
const ChangePassword = React.lazy(() =>
  import("./components/User/UserManagement/ChangePassword")
);

// Menu
const MenuTitle = React.lazy(() =>
  import("./components/Menu/MenuTitle/MenuTitle")
);
const MainMenu = React.lazy(() =>
  import("./components/Menu/MainMenu/MainMenu")
);
const SubMenu = React.lazy(() =>
  import("./components/Menu/SubMenu/SubMenu")
);
const IconClassExample = React.lazy(() =>
  import("./components/Menu/MainMenu/IconClassExample")
);
const MenuStructureMaintenance = React.lazy(() =>
  import("./components/Menu/MenuStructureMaintenance/MenuStructureMaintenance")
);

// Agency Master
const AgencyTypeMaster = React.lazy(() =>
  import("./components/AgencyMaster/AgencyTypeMaster/AgencyTypeMaster")
);
const DepartmentMaster = React.lazy(() =>
  import("./components/AgencyMaster/DepartmentMaster/DepartmentMaster")
);
const DivisionRegionalOfficeMaster = React.lazy(() =>
  import("./components/AgencyMaster/DivisionRegionalOfficeMaster/DivisionRegionalOfficeMaster")
);
const MinistryOrganizationMaster = React.lazy(() =>
  import("./components/AgencyMaster/MinistryOrganizationMaster/MinistryOrganizationMaster")
);

// Location Master
const RegionalOffice = React.lazy(() =>
  import("./components/LocationMaster/RegionalOffice/RegionalOffice")
);
const Dzongkhag = React.lazy(() =>
  import("./components/LocationMaster/Dzongkhag/Dzongkhag")
);
const Dungkhag = React.lazy(() =>
  import("./components/LocationMaster/Dungkhag/Dungkhag")
);
const Gewogs = React.lazy(() =>
  import("./components/LocationMaster/Gewogs/Gewogs")
);
const Village = React.lazy(() =>
  import("./components/LocationMaster/Village/Village")
);

// Other Master
const ModOfPayment = React.lazy(() =>
  import("./components/OthersMaster/ModOfPayment/ModOfPayment")
);
const Country = React.lazy(() =>
  import("./components/OthersMaster/Country/Country")
);
const CurrencyMaster = React.lazy(() =>
  import("./components/OthersMaster/CurrencyMaster/CurrencyMaster")
);
const PrepaymentType = React.lazy(() =>
  import("./components/OthersMaster/PrepaymentType/PrepaymentType")
);
const PaymentCollectionMethod = React.lazy(() =>
  import("./components/OthersMaster/PaymentCollectionMethod/PaymentCollectionMethod")
);
const BusinessSector = React.lazy(() =>
  import("./components/OthersMaster/BusinessSector/BusinessSector")
);
const Service = React.lazy(() =>
  import("./components/OthersMaster/Service/Service")
);
const AgencyServiceList = React.lazy(() =>
  import("./components/OthersMaster/Service/AgencyServiceList")
);
const StatusMaster = React.lazy(() =>
  import("./components/OthersMaster/StatusMaster/StatusMaster")
);
const SectoralList = React.lazy(() =>
  import("./components/OthersMaster/SectoralList/SectoralList")
);
const TaxProcess = React.lazy(() =>
  import("./components/OthersMaster/TaxProcess/TaxProcess")
);
const TaxTypeMaster = React.lazy(() =>
  import("./components/OthersMaster/TaxTypeMaster/TaxTypeMaster")
);
const ExternalSystemRegistration = React.lazy(() =>
  import("./components/OthersMaster/ExternalSystemRegistration/ExternalSystemRegistration")
);
const DisbursementAllowableMaster = React.lazy(() =>
  import("./components/OthersMaster/DisbursementAllowableMaster/DisbursementAllowableMaster")
);
const DisbursementType = React.lazy(() =>
  import("./components/OthersMaster/DisbursementType/DisbursementType")
);
const TransactionType = React.lazy(() =>
  import("./components/OthersMaster/TransactionType/TransactionType")
);
const ExchangeRate = React.lazy(() =>
  import("./components/OthersMaster/ExchangeRate/ExchangeRate")
);
const FinancialYears = React.lazy(() =>
  import("./components/OthersMaster/FinancialYears/FinancialYears")
);
const BankAccountType = React.lazy(() =>
  import("./components/OthersMaster/AccountType/AccountType")
);
const AuditType = React.lazy(() =>
  import("./components/OthersMaster/AuditType/AuditType")
);
const DisbursementThreshold = React.lazy(() =>
  import("./components/OthersMaster/DisbursementThreshold/DisbursementThreshold")
);
const TargetType = React.lazy(() =>
  import("./components/OthersMaster/TargetType/TargetType")
);

// COA Master
const COAChart = React.lazy(() =>
  import("./components/COAMaster/COAChart/COAChart")
);
const COAGroups = React.lazy(() =>
  import("./components/COAMaster/COAGroups/COAGroups")
);
const ConfigurationOption = React.lazy(() =>
  import("./components/COAMaster/ConfigurationOption/ConfigurationOption")
);
const MajorGroup = React.lazy(() =>
  import("./components/COAMaster/MajorGroup/MajorGroup")
);
const RevenueGroup = React.lazy(() =>
  import("./components/COAMaster/RevenueGroup/RevenueGroup")
);
const RevenueGroupList = React.lazy(() =>
  import("./components/COAMaster/RevenueGroup/RevenueGroupList")
);
const RevenueType = React.lazy(() =>
  import("./components/COAMaster/RevenueType/RevenueType")
);
const RevenueTypeList = React.lazy(() =>
  import("./components/COAMaster/RevenueType/RevenueTypeList")
);
const RevenueSubType = React.lazy(() =>
  import("./components/COAMaster/RevenueSubType/RevenueSubType")
);
const RevenueSubTypeList = React.lazy(() =>
  import("./components/COAMaster/RevenueSubType/RevenueSubTypeList")
);
const RevenueGLHead = React.lazy(() =>
  import("./components/COAMaster/RevenueGLHead/RevenueGLHead")
);
const RevenueGLHeadList = React.lazy(() =>
  import("./components/COAMaster/RevenueGLHead/RevenueGLHeadList")
);
const BankMaster = React.lazy(() =>
  import("./components/COAMaster/BankMaster/BankMaster")
);
const BankBranch = React.lazy(() =>
  import("./components/COAMaster/BankBranch/BankBranch")
);
const DRCBankAccount = React.lazy(() =>
  import("./components/COAMaster/DRCBankAccount/DRCBankAccount")
);
const RewardPayment = React.lazy(() =>
  import("./components/COAMaster/RewardPayment/RewardPayment")
);

// COA Application
const CoaApplicationRequest = React.lazy(() =>
  import("./components/CoaApplication/CoaApplicationRequest/CoaApplicationRequest")
);
const CoaApplicationReview = React.lazy(() =>
  import("./components/CoaApplication/CoaApplicationReview/CoaApplicationReview")
);

// BTC Master
const Edition = React.lazy(() =>
  import("./components/BtcMasters/Edition/Edition")
);
const Section = React.lazy(() =>
  import("./components/BtcMasters/Section/Section")
);
const Chapter = React.lazy(() =>
  import("./components/BtcMasters/Chapter/Chapter")
);
const Heading = React.lazy(() =>
  import("./components/BtcMasters/Heading/Heading")
);
const Commodities = React.lazy(() =>
  import("./components/BtcMasters/Commodities/Commodities")
);
const BtcUpload = React.lazy(() =>
  import("./components/BtcMasters/BtcUpload")
);

// COA Mapping
const CoaMapping = React.lazy(() =>
  import("./components/CoaMapping/CoaMapping/CoaMapping")
);

// Agency
const AgencyRegistration = React.lazy(() =>
  import("./components/AgencyRegistration/AgencyRegistration")
);
const AgencyRegistrationReview = React.lazy(() =>
  import("./components/AgencyRegistration/AgencyRegistrationReview")
);
const AgencyList = React.lazy(() =>
  import("./components/AgencyMaintenance/AgencyList")
);
const AgencyRequestList = React.lazy(() =>
  import("./components/AgencyRegistration/AgencyRequestList")
);
const AgencyMaintenanceForm = React.lazy(() =>
  import("./components/AgencyMaintenance/AgencyMaintenanceForm")
);
const AgencyContactPerson = React.lazy(() =>
  import("./components/AgencyMaintenance/AgencyContactPerson/AgencyContactPerson")
);
const AgencyRrcoDzongkhag = React.lazy(() =>
  import("./components/AgencyMaintenance/AgencyRrcoDzongkhag/AgencyRrcoDzongkhag")
);

// User Management
const UserManagement = React.lazy(() =>
  import("./components/User/UserManagement/UserManagement")
);
const UserManageAdd = React.lazy(() =>
  import("./components/User/UserManagement/UserManageAdd")
);
const UserManageEdit = React.lazy(() =>
  import("./components/User/UserManagement/UserManageEdit")
);
const UserRequestForm = React.lazy(() =>
  import("./components/User/UserRequestForm/UserRequestForm")
);
const UserReviewDecision = React.lazy(() =>
  import("./components/User/UserReview/UserReviewDecision")
);
const UserApplicationList = React.lazy(() =>
  import("./components/User/UserReview/UserApplicationList")
);
const RoleManagement = React.lazy(() =>
  import("./components/User/RoleManagement/RoleManagement")
);
const RolePermission = React.lazy(() =>
  import("./components/User/RoleManagement/RolePermission")
);
const ProfileManagement = React.lazy(() =>
  import("./components/User/ProfileManagement/ProfileManagement")
);
const AuthConfiguration = React.lazy(() =>
  import("./components/User/AuthConfiguration/AuthConfiguration")
);

// Payment
// Payment Advice
const PaymentAdvice = React.lazy(() =>
  import("./components/PaymentAdvice/PaymentAdvice")
);
const PaymentAdviceAddPage = React.lazy(() =>
  import("./components/PaymentAdvice/PaymentAdviceCounter/AddPage/AddPage")
);
const PaymentAdviceCounterReceipt = React.lazy(() =>
  import("./components/PaymentAdvice/PaymentAdviceCounter/PaymentAdviceCounterReceipt")
);
const PaymentAdviceMaintainPage = React.lazy(() =>
  import("./components/PaymentAdvice/PaymentAdviceCounter/MaintainPage/MaintainPage")
);
const PaymentAdviceViewPage = React.lazy(() =>
  import("./components/PaymentAdvice/PaymentAdviceViewPage")
);

// Prepayment Advice
const PrepaymentAdviceRequest = React.lazy(() =>
  import("./components/PrepaymentAdvice/PrepaymentAdviceRequest/PrepaymentAdviceRequest")
);
const PrepaymentAdviceReview = React.lazy(() =>
  import("./components/PrepaymentAdvice/PrepaymentAdviceReview/PrepaymentAdviceReview")
);

// Counter Payment
const CounterPayment = React.lazy(() =>
  import("./components/CounterPayment/CounterPayment")
);
const CounterPaymentReceipt = React.lazy(() =>
  import("./components/CounterPayment/CounterPaymentReceipt")
);
const CounterPaymentPreprintedReceipt = React.lazy(() =>
  import("./components/CounterPayment/CounterPaymentPreprintedReceipt")
);

// PA Grouping
const PaGrouping = React.lazy(() =>
  import("./components/PaGrouping/PaGrouping")
);
const PaGroupingCreate = React.lazy(() =>
  import("./components/PaGrouping/PaGroupingCreate")
);
const PaGroupingReceipt = React.lazy(() =>
  import("./components/PaGrouping/PaGroupingReceipt")
);

// PA Grouping Maintenance
const PaGroupingMaintenance = React.lazy(() =>
  import("./components/PaGroupingMaintenance/PaGroupingMaintenance")
);
const PaGroupingCancel = React.lazy(() =>
  import("./components/PaGroupingMaintenance/PaGroupingCancel")
);
const PaGroupingUnmap = React.lazy(() =>
  import("./components/PaGroupingMaintenance/PaGroupingUnmap")
);

// Receipt List
const ReceiptList = React.lazy(() =>
  import("./components/ReceiptList/ReceiptList")
);
const ReceiptView = React.lazy(() =>
  import("./components/ReceiptList/ReceiptView")
);

// Receipt Maintenance
const ReceiptMaintenance = React.lazy(() =>
  import("./components/ReceiptMaintenance/ReceiptMaintenance")
);
const ReceiptReprint = React.lazy(() =>
  import("./components/ReceiptMaintenance/ReceiptReprint")
);
const AmendReceipt = React.lazy(() =>
  import("./components/ReceiptMaintenance/AmendReceipt")
);
const UnmapReceipt = React.lazy(() =>
  import("./components/ReceiptMaintenance/UnmapReceipt")
);
const ReceiptMaintenanceAppList = React.lazy(() =>
  import("./components/ReceiptMaintenance/ReceiptMaintenanceApproval/ReceiptMaintenanceAppList")
);
const AmendApproval = React.lazy(() =>
  import("./components/ReceiptMaintenance/ReceiptMaintenanceApproval/AmendApproval")
);
// const AmendedReceipt = React.lazy(() =>
//   import("./components/ReceiptMaintenance/ReceiptMaintenanceApproval/AmendedReceipt")
// );
const UnmapPaymentApproval = React.lazy(() =>
  import("./components/ReceiptMaintenance/ReceiptMaintenanceApproval/UnmapPaymentApproval")
);
const ReversalApproval = React.lazy(() =>
  import("./components/ReceiptMaintenance/ReceiptMaintenanceApproval/ReversalApproval")
);

// Deposit Slip
const DepositSlip = React.lazy(() =>
  import("./components/DepositSlip/DepositSlip/DepositSlip")
);
const DepositSlipCreate = React.lazy(() =>
  import("./components/DepositSlip/DepositSlipCreate/DepositSlipCreate")
);
const CashDepositSlip = React.lazy(() =>
  import("./components/DepositSlip/DepositSlipReceipt/CashDepositSlip")
);
const BankDepositSlip = React.lazy(() =>
  import("./components/DepositSlip/DepositSlipReceipt/BankDepositSlip")
);

// Deposit Maintenance
const DepositMaintenance = React.lazy(() =>
  import("./components/DepositMaintenance/DepositMaintenance")
);
const DepositSlipView = React.lazy(() =>
  import("./components/DepositMaintenance/DepositSlipView/DepositSlipView")
);
const DepositSlipReprint = React.lazy(() =>
  import("./components/DepositMaintenance/DepositSlipReprint/DepositSlipReprint")
);
const DepositSlipUnmap = React.lazy(() =>
  import("./components/DepositMaintenance/DepositSlipUnmap/DepositSlipUnmap")
);
const DepositSlipUnmapReceipt = React.lazy(() =>
  import("./components/DepositMaintenance/DepositSlipUnmap/DepositSlipUnmapReceipt")
);
const DepositSlipCancel = React.lazy(() =>
  import("./components/DepositMaintenance/DepositSlipCancel/DepositSlipCancel")
);

// Cheque Bounce
const ChequeBounce = React.lazy(() =>
  import("./components/ChequeBounce/ChequeBounce")
);

//Cheque Bounce List
const ChequeBounceList = React.lazy(() =>
  import("./components/ChequeBounceList/ChequeBounceList")
);

// Reconciliation
const Reconciliation = React.lazy(() =>
  import("./components/Reconciliation/Reconciliation")
);
const ReconciliationView = React.lazy(() =>
  import("./components/Reconciliation/ReconciliationView")
);

// Reconciliation Approval
const ReconciliationReview = React.lazy(() =>
  import("./components/ReconciliationApproval/ReconciliationReview")
);
const ReconciliationApproval = React.lazy(() =>
  import("./components/ReconciliationApproval/ReconciliationApproval")
);

// Reconciliation Bulk
const ReconciliationBulk = React.lazy(() =>
  import("./components/ReconciliationBulk/ReconciliationBulk")
);
const ReconciliationBulkView = React.lazy(() =>
  import("./components/ReconciliationBulk/ReconciliationBulkView")
);

// Reconciliation Bulk Approval
const ReconciliationBulkApproval = React.lazy(() =>
  import("./components/ReconciliationBulkApproval/ReconciliationBulkApproval")
);
const ReconciliationBulkReview = React.lazy(() =>
  import("./components/ReconciliationBulkApproval/ReconciliationBulkReview")
);

// RMA Failed Transactions
const RMAFailedTransactionRequest = React.lazy(() =>
  import("./components/RMAFailedTransaction/TransactionRequest/TransactionRequest")
);
const RMAFailedTransactionAddPage = React.lazy(() =>
  import("./components/RMAFailedTransaction/TransactionRequest/AddPage")
);
const RMAFailedTransactionReview = React.lazy(() =>
  import("./components/RMAFailedTransaction/TransactionReview/TransactionReview")
);

// Group Deposit Slip
const GroupDepositSlip = React.lazy(() =>
  import("./components_phase_2/GroupDepositSlip/GroupDepositSlip")
);
const GroupDepositSlipView = React.lazy(() =>
  import("./components_phase_2/GroupDepositSlip/GroupDepositSlipView")
);
const GroupDepositSlipMaintenance = React.lazy(() =>
  import("./components_phase_2/GroupDepositSlip/GroupDepositSlipMaintenance")
);


// Report
// Phase-One Reports
const AgencyWiseCoa = React.lazy(() =>
  import("./components/Reports/AgencyWiseCoa/AgencyWiseCoa")
);
const UserRoleList = React.lazy(() =>
  import("./components/Reports/UserRoleList/UserRoleList")
);
const AgencyRegistrationReport = React.lazy(() =>
  import("./components/Reports/AgencyRegistration/AgencyRegistrationReport")
);
const AssignedRevenueHead = React.lazy(() =>
  import("./components/Reports/AssignedRevenueHead/AssignedRevenueHead")
);
const UserApplicationReport = React.lazy(() =>
  import("./components/Reports/UserApplicationReport/UserApplicationReport")
);
const CashBook = React.lazy(() =>
  import("./components/Reports/CashBookReport/CashBook")
);
const ScheduleColDepositStatement = React.lazy(() =>
  import("./components/Reports/ScheduleColDepositStatement/ScheduleColDepositStatement")
);
const SummeryColDepositStatement = React.lazy(() =>
  import("./components/Reports/SummeryColDepositStatement/SummeryColDepositStatement")
);
const ReceiptsDepositStatement = React.lazy(() =>
  import("./components/Reports/ReceiptsDepositStatement/ReceiptsDepositStatement")
);
const CollectionDetails = React.lazy(() =>
  import("./components/Reports/CollectionDetails/CollectionDetails")
);
const DepositDetails = React.lazy(() =>
  import("./components/Reports/DepositDetails/DepositDetails")
);
const BankReconciliationStatement = React.lazy(() =>
  import("./components/Reports/BankReconciliationStatement/BankReconciliationStatement")
);
const ReconciliationStatement = React.lazy(() =>
  import("./components/Reports/BankReconciliationStatement/ReconciliationStatement")
);
const BankStatement = React.lazy(() =>
  import("./components/Reports/BankStatement/BankStatement")
);
const ChequeDishonoredStatement = React.lazy(() =>
  import("./components/Reports/ChequeDishonoredStatement/ChequeDishonoredStatement")
);
const PrePayDepositAdjustmentStatement = React.lazy(() =>
  import("./components/Reports/PrePayAdjustmentDepositStatement/PrePayDepositAdjustmentStatement")
);
const PrePayDepositStatement = React.lazy(() =>
  import("./components/Reports/PrePayDepositStatement/PrePayDepositStatement")
);
const Ledger = React.lazy(() =>
  import("./components/Reports/Ledger/Ledger")
);
const ConsolidatedBankLedger = React.lazy(() =>
  import("./components/Reports/ConsolidatedBankLedger/ConsolidatedBankLedger")
);
const AllAgenciesConsolidatedBankLedger = React.lazy(() =>
  import("./components/Reports/AllAgenciesConsolidatedBankLedger/AllAgenciesConsolidatedBankLedger")
);
const AgenciesConsolidatedBankLedger = React.lazy(() =>
  import("./components/Reports/AllAgenciesConsolidatedBankLedger/AgenciesConsolidatedBankLedger")
);
const DemandCollectionRegister = React.lazy(() =>
  import("./components/Reports/DemandCollectionRegister/DemandCollectionRegister")
);
const SbaCashBook = React.lazy(() =>
  import("./components/Reports/SbaCashBook/SbaCashBook")
);
const ReceiptReversalStatement = React.lazy(() =>
  import("./components/Reports/ReceiptReversalStatementReport/ReceiptReversalStatement")
);
const AgencyWiseUser = React.lazy(() =>
  import("./components/Reports/AgencyWiseUser/AgencyWiseUser")
);
const UserStatus = React.lazy(() =>
  import("./components/Reports/UserStatus/UserStatus")
);
const RMASinglePayment = React.lazy(() =>
  import("./components/Reports/RMASinglePayment/RMASinglePayment")
);
const RMAGroupPayment = React.lazy(() =>
  import("./components/Reports/RMAGroupPayment/RMAGroupPayment")
);

// Phase-Two Reports
const StockInventoryStatement = React.lazy(() =>
  import("./components_phase_2/Reports/StockInventoryStatement/StockInventoryStatement")
);
const StockRegister = React.lazy(() =>
  import("./components_phase_2/Reports/StockRegister/StockRegister")
);
const RefundPaymentStatement = React.lazy(() =>
  import("./components_phase_2/Reports/RefundPaymentStatement/RefundPaymentStatement")
);
const RewardAfterDepositStatement = React.lazy(() =>
  import("./components_phase_2/Reports/RewardAfterDepositStatement/RewardAfterDepositStatement")
);
const RewardBeforeDepositStatement = React.lazy(() =>
  import("./components_phase_2/Reports/RewardBeforeDepositStatement/RewardBeforeDepositStatement")
);
const AdvancePaymentStatement = React.lazy(() =>
  import("./components_phase_2/Reports/AdvancePaymentStatement/AdvancePaymentStatement")
);
const AdvanceAdjustmentStatement = React.lazy(() =>
  import("./components_phase_2/Reports/AdvanceAdjustmentStatement/AdvanceAdjustmentStatement")
);
const AdvanceAdjustmentSummary = React.lazy(() =>
  import("./components_phase_2/Reports/AdvanceAdjustmentSummary.js/AdvanceAdjustmentSummary")
);
const AdvanceReimbursementSummary = React.lazy(() =>
  import("./components_phase_2/Reports/AdvanceReimbursementSummary/AdvanceReimbursementSummary")
);
const ReplenishmentReport = React.lazy(() =>
  import("./components_phase_2/Reports/ReplenishmentReport/ReplenishmentReport")
);
const AdvanceReimbursementStatement = React.lazy(() =>
  import("./components_phase_2/Reports/AdvanceReimbursementStatement/AdvanceReimbursementStatement")
);
const DisbursementVoucherStatement = React.lazy(() =>
  import("./components_phase_2/Reports/DisbursementVoucherStatement/DisbursementVoucherStatement")
);
const DisbursementVoucherDetails = React.lazy(() =>
  import("./components_phase_2/Reports/DisbursementVoucherDetails/DisbursementVoucherDetails")
);
const DisbursementVoucherCancellationStatement = React.lazy(() =>
  import("./components_phase_2/Reports/DisbursementVoucherCancellationStatement/DisbursementVoucherCancellationStatement")
);
const NettingOffStatement = React.lazy(() =>
  import("./components_phase_2/Reports/NettingOffStatement/NettingOffStatement")
);
const DelayedDeposit = React.lazy(() =>
  import("./components_phase_2/Reports/DelayedDeposit/DelayedDeposit")
);
const WriteOffStatement = React.lazy(() =>
  import("./components_phase_2/Reports/WriteOffStatement/WriteOffStatement")
);
const Top10Collection = React.lazy(() =>
  import("./components_phase_2/Reports/Top10Collection/Top10Collection")
);
const ReceiptReversalFrequency = React.lazy(() =>
  import("./components_phase_2/Reports/ReceiptReversalFrequency/ReceiptReversalFrequency")
);
const SectoralReport = React.lazy(() =>
  import("./components_phase_2/Reports/SectoralReport/SectoralReport")
);
const NationalRevenueReport = React.lazy(() =>
  import("./components_phase_2/Reports/NationalRevenueReport/NationalRevenueReport")
);
const MonthlyNationalRevenueReport = React.lazy(() =>
  import("./components_phase_2/Reports/MonthlyNationalRevenueReport/MonthlyNationalRevenueReport")
);
const ComparativeReport = React.lazy(() =>
  import("./components_phase_2/Reports/ComparativeReport/ComparativeReport")
);
const BudgetedTargets = React.lazy(() =>
  import("./components_phase_2/Reports/BudgetedTargets/BudgetedTargets")
);
const TaxToGDP = React.lazy(() =>
  import("./components_phase_2/Reports/TaxToGDP/TaxToGDP")
);




//---------------------- PHASE-2 START----------------------//
// Receipt Stock
const RRCOReceiptStock = React.lazy(() =>
  import("./components_phase_2/ReceiptStock/RRCOReceiptStock/RRCOReceiptStock")
);
const DRCReceiptStock = React.lazy(() =>
  import("./components_phase_2/ReceiptStock/DRCReceiptStock/DRCReceiptStock")
);
const ReceiptAllocationToRRCO = React.lazy(() =>
  import("./components_phase_2/ReceiptStock/ReceiptAllocationToRRCO/ReceiptAllocationToRRCO")
);
const ReceiptAllocationToAgencies = React.lazy(() =>
  import("./components_phase_2/ReceiptStock/ReceiptAllocationToAgencies/ReceiptAllocationToAgencies")
);
const AgencyReceiptStock = React.lazy(() =>
  import("./components_phase_2/ReceiptStock/AgencyReceiptStock/AgencyReceiptStock")
);


// Refund & Disbursement 
// Vendor Registration
const VendorRegistration = React.lazy(() =>
  import("./components_phase_2/RefundAndDisbursement/VendorRegistration/VendorRegistration")
);

// Advance Payment
const AdvancePaymentRequest = React.lazy(() =>
  import("./components_phase_2/RefundAndDisbursement/AdvancePayment/AdvancePaymentRequest/AdvancePaymentRequest")
);
const AdvancePaymentReceipt = React.lazy(() =>
  import("./components_phase_2/RefundAndDisbursement/AdvancePayment/AdvancePaymentRequest/AdvancePaymentReceipt")
);
const AdvancePaymentReprint = React.lazy(() =>
  import("./components_phase_2/RefundAndDisbursement/AdvancePayment/AdvancePaymentRequest/AdvancePaymentReprint")
);
const AdvancePaymentReview = React.lazy(() =>
  import("./components_phase_2/RefundAndDisbursement/AdvancePayment/AdvancePaymentReview/AdvancePaymentReview")
);
const AdvancePaymentPrint = React.lazy(() =>
  import("./components_phase_2/RefundAndDisbursement/AdvancePayment/AdvancePaymentReview/AdvancePaymentPrint")
);

// Payment Voucher
const PaymentVoucherRequest = React.lazy(() =>
  import("./components_phase_2/RefundAndDisbursement/PaymentVoucher/PaymentVoucherRequest/PaymentVoucherRequest")
);
const PaymentVoucherReview = React.lazy(() =>
  import("./components_phase_2/RefundAndDisbursement/PaymentVoucher/PaymentVoucherReview/PaymentVoucherReview")
);
const PaymentVoucherPrint = React.lazy(() =>
  import("./components_phase_2/RefundAndDisbursement/PaymentVoucher/PaymentVoucherRequest/PaymentVoucherPrint")
);

// Payment Voucher Maintenance
const PaymentVoucherMaintain = React.lazy(() =>
  import("./components_phase_2/RefundAndDisbursement/PaymentVoucherMaintain/PaymentVoucherMaintain/PaymentVoucherMaintain")
);
const PaymentVoucherMaintainReview = React.lazy(() =>
  import("./components_phase_2/RefundAndDisbursement/PaymentVoucherMaintain/PaymentVoucherMaintainReview/PaymentVoucherMaintainReview")
);
const PaymentVoucherReprint = React.lazy(() =>
  import("./components_phase_2/RefundAndDisbursement/PaymentVoucherMaintain/PaymentVoucherMaintain/PaymentVoucherReprint")
);
const ReplenishmentReprint = React.lazy(() =>
  import("./components_phase_2/RefundAndDisbursement/PaymentVoucherMaintain/PaymentVoucherMaintain/ReplenishmentReprint")
);

// Replenishment
const ReplenishmentRequest = React.lazy(() =>
  import("./components_phase_2/RefundAndDisbursement/Replenishment/ReplenishmentRequest/ReplenishmentRequest")
);
const ReplenishmentReview = React.lazy(() =>
  import("./components_phase_2/RefundAndDisbursement/Replenishment/ReplenishmentReview/ReplenishmentReview")
);
const ReplenishmentApproval = React.lazy(() =>
  import("./components_phase_2/RefundAndDisbursement/Replenishment/ReplenishmentApproval/ReplenishmentApproval")
);
const ReplenishmentPrint = React.lazy(() =>
  import("./components_phase_2/RefundAndDisbursement/Replenishment/ReplenishmentRequest/ReplenishmentPrint")
);
const ReplenishmentReceiptVoucher = React.lazy(() =>
  import("./components_phase_2/RefundAndDisbursement/Replenishment/ReplenishmentRequest/ReplenishmentReceiptVoucher")
);
const ReplenishmentDrc = React.lazy(() =>
  import("./components_phase_2/RefundAndDisbursement/Replenishment/ReplenishmentDrc/ReplenishmentDrc")
);
const ReplenishmentReceiptVoucherDrc = React.lazy(() =>
  import("./components_phase_2/RefundAndDisbursement/Replenishment/ReplenishmentDrc/ReplenishmentReceiptVoucherDrc")
);

// Refund Application
const RefundApplication = React.lazy(() =>
  import("./components_phase_2/RefundAndDisbursement/Refund/RefundApplication/RefundApplication")
);
const VerifyRefundApplication = React.lazy(() =>
  import("./components_phase_2/RefundAndDisbursement/Refund/VerifyRefundApplication/VerifyRefundApplication")
);
const ReviewRefundApplication = React.lazy(() =>
  import("./components_phase_2/RefundAndDisbursement/Refund/ReviewRefundApplication/ReviewRefundApplication")
);
const RefundVoucher = React.lazy(() =>
  import("./components_phase_2/RefundAndDisbursement/Refund/RefundApplication/RefundVoucher")
);

// Refund Amendment
const RefundAmendment = React.lazy(() =>
  import("./components_phase_2/RefundAndDisbursement/RefundAmendment/RefundAmendment/RefundAmendment")
);
const RefundAmendmentReview = React.lazy(() =>
  import("./components_phase_2/RefundAndDisbursement/RefundAmendment/RefundAmendmentReview/RefundAmendmentReview")
);
const RefundAmendmentApproval = React.lazy(() =>
  import("./components_phase_2/RefundAndDisbursement/RefundAmendment/RefundAmendmentApproval/RefundAmendmentApproval")
);

// Reward Before Deposit
const RewardBeforeDeposit = React.lazy(() =>
  import("./components_phase_2/RefundAndDisbursement/RewardBeforeDeposit/RewardBeforeDeposit")
);
const RewardBeforeDepositVoucher = React.lazy(() =>
  import("./components_phase_2/RefundAndDisbursement/RewardBeforeDeposit/RewardBeforeDepositVoucher")
);

// Disbursement Voucher
const DisbursementVoucher = React.lazy(() =>
  import("./components_phase_2/RefundAndDisbursement/Disbursement/DisbursementVoucher/DisbursementVoucher")
);
const DisbursementVoucherApprove = React.lazy(() =>
  import("./components_phase_2/RefundAndDisbursement/Disbursement/DisbursementVoucherApprove/DisbursementVoucherApprove")
);
const DisbursementVoucherView = React.lazy(() =>
  import("./components_phase_2/RefundAndDisbursement/Disbursement/DisbursementVoucher/DisbursementVoucherView")
);
const DisbursementVoucherMaintenance = React.lazy(() =>
  import("./components_phase_2/RefundAndDisbursement/Disbursement/DisbursementVoucherMaintenance/DisbursementVoucherMaintenance")
);
const DisbursementVoucherReprint = React.lazy(() =>
  import("./components_phase_2/RefundAndDisbursement/Disbursement/DisbursementVoucherMaintenance/DisbursementVoucherReprint")
);
const InitiateDisbursement = React.lazy(() =>
  import("./components_phase_2/RefundAndDisbursement/Disbursement/DisbursementVoucher/InitiateDisbursement")
);
const UnsuccessfulDisbursement = React.lazy(() =>
  import("./components_phase_2/RefundAndDisbursement/Disbursement/UnsuccessfulDisbursement/UnsuccessfulDisbursement")
);

// Reconciliation Maintenance
const ReconciliationMaintenanceApplication = React.lazy(() =>
  import("./components_phase_2/ReconciliationMaintenance/ReconciliationMaintenanceApplication/ReconciliationMaintenanceApplication")
);
const ReconciliationMaintenanceApproval = React.lazy(() =>
  import("./components_phase_2/ReconciliationMaintenance/ReconciliationMaintenanceApproval/ReconciliationMaintenanceApproval")
);

// Withdrawal Deposit
const WithdrawalDeposit = React.lazy(() =>
  import("./components_phase_2/WithdrawalDeposit/WithdrawalDeposit")
);
const WithdrawalAndDepositVoucher = React.lazy(() =>
  import("./components_phase_2/WithdrawalDeposit/WithdrawalAndDepositVoucher")
);

// Advance Adjustment
const AdvanceAdjustment = React.lazy(() =>
  import("./components_phase_2/AdvanceAdjustment/AdvanceAdjustment")
);

// Reimbursement
const Reimbursement = React.lazy(() =>
  import("./components_phase_2/Reimbursement/Reimbursement")
);
const ReimbursementVoucher = React.lazy(() =>
  import("./components_phase_2/Reimbursement/ReimbursementVoucher")
);

// Netting Off
const NettingOffRequest = React.lazy(() =>
  import("./components_phase_2/NettingOff/NettingOffRequest/NettingOffRequest")
);
const NettingOffReview = React.lazy(() =>
  import("./components_phase_2/NettingOff/NettingOffReview/NettingOffReview")
);


// ECMS
const PaymentAdviceSuccessStatus = React.lazy(() =>
  import("./components_phase_2/ExternalSystemIntegration/PaymentAdviceSuccessStatus")
);
const LogSearchPage = React.lazy(() =>
  import("./components_phase_2/ExternalSystemIntegration/LogSearchPage")
);


// Risk management
const RiskProfileEntity = React.lazy(() =>
  import("./components_phase_2/RiskManagement/RiskProfileEntity/RiskProfileEntity")
);
const RiskAction = React.lazy(() =>
  import("./components_phase_2/RiskManagement/RiskAction/RiskAction")
);
// const RiskTransactionProcess = React.lazy(() =>
//   import("./components_phase_2/RiskManagement/RiskTransactionProcess/RiskTransactionProcess")
// );
const RiskLevel = React.lazy(() =>
  import("./components_phase_2/RiskManagement/RiskLevel/RiskLevel")
);
const RiskParameterIndicator = React.lazy(() =>
  import("./components_phase_2/RiskManagement/RiskParameterIndicator/RiskParameterIndicator")
);
const RiskParameterValue = React.lazy(() =>
  import("./components_phase_2/RiskManagement/RiskParameterValue/RiskParameterValue")
);
const RiskArea = React.lazy(() =>
  import("./components_phase_2/RiskManagement/RiskArea/RiskArea")
);
const RiskSubArea = React.lazy(() =>
  import("./components_phase_2/RiskManagement/RiskSubArea/RiskSubArea")
);
const RiskLevelThreshold = React.lazy(() =>
  import("./components_phase_2/RiskManagement/RiskLevelThreshold/RiskLevelThreshold")
);
const RiskRule = React.lazy(() =>
  import("./components_phase_2/RiskManagement/RiskRule/RiskRule")
);
const RiskProfile = React.lazy(() =>
  import("./components_phase_2/RiskManagement/RiskProfile/RiskProfile")
);
const RiskProfileReport = React.lazy(() =>
  import("./components_phase_2/RiskManagement/RiskProfileReport/RiskProfileReport")
);
const RiskProfileReportDetails = React.lazy(() =>
  import("./components_phase_2/RiskManagement/RiskProfileReport/RiskProfileReportDetails")
);
// const RiskAssessment = React.lazy(() =>
//   import("./components_phase_2/RiskManagement/RiskAssessment/RiskAssessment")
// );
const RiskNotification = React.lazy(() =>
  import("./components_phase_2/RiskManagement/RiskNotification/RiskNotification")
);


// Audit management
const AuditPlan = React.lazy(() =>
  import("./components_phase_2/AuditManagement/AuditSupport/AuditPlan/AuditPlan")
);
const AuditSupport = React.lazy(() =>
  import("./components_phase_2/AuditManagement/AuditSupport/AuditPlan/AuditPlanAddPage")
);
const Auditing = React.lazy(() =>
  import("./components_phase_2/AuditManagement/AuditSupport/Auditing/Auditing")
);
const AuditReportCreate = React.lazy(() =>
  import("./components_phase_2/AuditManagement/AuditSupport/AuditReport/AuditReportCreate")
);
const AuditReportUpdate = React.lazy(() =>
  import("./components_phase_2/AuditManagement/AuditSupport/AuditReport/AuditReportUpdate")
);
const PreliminaryAuditReportCreate = React.lazy(() =>
  import("./components_phase_2/AuditManagement/AuditSupport/Auditing/PreliminaryAuditReportCreate")
);
const PreliminaryAuditReportGenerate = React.lazy(() =>
  import("./components_phase_2/AuditManagement/AuditSupport/Auditing/PreliminaryAuditReportGenerate")
);
const PrelimAuditReportReview = React.lazy(() =>
  import("./components_phase_2/AuditManagement/AuditSupport/PreliminaryAuditReport/PrelimAuditReportReview/PrelimAuditReportReview")
);
const PrelimAuditReportReviewDecision = React.lazy(() =>
  import("./components_phase_2/AuditManagement/AuditSupport/PreliminaryAuditReport/PrelimAuditReportReview/PrelimAuditReportReviewDecision")
);
const PrelimAuditReportApproval = React.lazy(() =>
  import("./components_phase_2/AuditManagement/AuditSupport/PreliminaryAuditReport/PrelimAuditReportApproval/PrelimAuditReportApproval")
);
const PrelimAuditReportApprovalDecision = React.lazy(() =>
  import("./components_phase_2/AuditManagement/AuditSupport/PreliminaryAuditReport/PrelimAuditReportApproval/PrelimAuditReportApprovalDecision")
);
const AuditMaintenance = React.lazy(() =>
  import("./components_phase_2/AuditManagement/AuditSupport/AuditMaintenance/AuditMaintenance")
);
const AuditReportMaintain = React.lazy(() =>
  import("./components_phase_2/AuditManagement/AuditSupport/AuditMaintenance/AuditReportMaintain")
);
const PreliminaryAuditReportMaintain = React.lazy(() =>
  import("./components_phase_2/AuditManagement/AuditSupport/AuditMaintenance/PreliminaryAuditReportMaintain")
);
const FinalAuditReportGenerate = React.lazy(() =>
  import("./components_phase_2/AuditManagement/FinalAuditReport/FinalAuditReport/FinalAuditReportGenerate")
);
const FinalAuditReportReview = React.lazy(() =>
  import("./components_phase_2/AuditManagement/FinalAuditReport/FinalAuditReportReview/FinalAuditReportReview")
);
const FinalAuditReportReviewDecision = React.lazy(() =>
  import("./components_phase_2/AuditManagement/FinalAuditReport/FinalAuditReportReview/FinalAuditReportReviewDecision")
);
const FinalAuditReportApprove = React.lazy(() =>
  import("./components_phase_2/AuditManagement/FinalAuditReport/FinalAuditReportApprove/FinalAuditReportApprove")
);
const FinalAuditReportApproveDecision = React.lazy(() =>
  import("./components_phase_2/AuditManagement/FinalAuditReport/FinalAuditReportApprove/FinalAuditReportApproveDecision")
);
const FinalAuditReportBeforeATR = React.lazy(() =>
  import("./components_phase_2/AuditManagement/FinalAuditReport/FinalAuditReport/FinalAuditReportBeforeATR")
);
const FinalAuditReportAfterATR = React.lazy(() =>
  import("./components_phase_2/AuditManagement/FinalAuditReport/FinalAuditReport/FinalAuditReportAfterATR")
);
const AuditResponse = React.lazy(() =>
  import("./components_phase_2/AuditManagement/AuditResponse/AuditResponse")
);
const AuditResponseReportView = React.lazy(() =>
  import("./components_phase_2/AuditManagement/AuditResponse/AuditResponseReportView")
);
const AuditResponseUpdate = React.lazy(() =>
  import("./components_phase_2/AuditManagement/AuditResponse/AuditResponseUpdate")
);
const AuditResponseReview = React.lazy(() =>
  import("./components_phase_2/AuditManagement/AuditResponseReview/AuditResponseReview")
);
const AuditResponseReviewPreliminaryReport = React.lazy(() =>
  import("./components_phase_2/AuditManagement/AuditResponseReview/AuditResponseReviewPreliminaryReport")
);
const AuditResponseReviewDetails = React.lazy(() =>
  import("./components_phase_2/AuditManagement/AuditResponseReview/AuditResponseReviewDetails")
);
const ActionToBeTaken = React.lazy(() =>
  import("./components_phase_2/AuditManagement/ActionToBeTaken/ActionToBeTaken")
);
const ObservationsRecommendations = React.lazy(() =>
  import("./components_phase_2/AuditManagement/ActionToBeTaken/ObservationsRecommendations")
);
const AuditReportsDrc = React.lazy(() =>
  import("./components_phase_2/AuditManagement/AuditReportsDrc/AuditReportsDrc")
);
const AuditReportsDrcObservations = React.lazy(() =>
  import("./components_phase_2/AuditManagement/AuditReportsDrc/AuditReportsDrcObservations")
);
const UpdateLegalAction = React.lazy(() =>
  import("./components_phase_2/AuditManagement/UpdateLegalAction/UpdateLegalAction")
);
const LegalActionObservations = React.lazy(() =>
  import("./components_phase_2/AuditManagement/UpdateLegalAction/LegalActionObservations")
);
const AuditNotification = React.lazy(() =>
  import("./components_phase_2/AuditManagement/AuditNotification/AuditNotification")
);


// Revenue Forecast
const PreviousYearRevenue = React.lazy(() =>
  import("./components_phase_2/PreviousYear/PreviousYearRevenue/PreviousYearRevenue")
);
const PreviousYearRevenueReview = React.lazy(() =>
  import("./components_phase_2/PreviousYear/PreviousYearRevenueReview/PreviousYearRevenueReview")
);
const PreviousYearRevenueMaintenanceTab = React.lazy(() =>
  import("./components_phase_2/PreviousYear/PreviousYearRevenueMaintenance/PreviousYearRevenueMaintenanceTab")
);
const PreviousYearRevenueMaintenanceReview = React.lazy(() =>
  import("./components_phase_2/PreviousYear/PreviousYearRevenueMaintenanceReview/PreviousYearRevenueMaintenanceReview")
);
const RevenueForecastApplication = React.lazy(() =>
  import("./components_phase_2/RevenueForecast/RevenueForecastApplication/RevenueForecastApplication")
);
const RevenueForecastApplicationReview = React.lazy(() =>
  import("./components_phase_2/RevenueForecast/RevenueForecastApplicationReview/RevenueForecastApplicationReview")
);
const RevenueForecastMaintenanceTab = React.lazy(() =>
  import("./components_phase_2/RevenueForecast/RevenueForecastMaintenance/RevenueForecastMaintenanceTab")
);
const RevenueForecastMaintenanceReview = React.lazy(() =>
  import("./components_phase_2/RevenueForecast/RevenueForecastMaintenanceReview/RevenueForecastMaintenanceReview")
);
const ReviseForecastReview = React.lazy(() =>
  import("./components_phase_2/RevenueForecast/ReviseForecastReview/ReviseForecastReview")
);
const ReviseForecastMaintenanceTab = React.lazy(() =>
  import("./components_phase_2/RevenueForecast/ReviseForecastMaintenance/ReviseForecastMaintenanceTab")
);
const ReviseForecastMaintenanceReview = React.lazy(() =>
  import("./components_phase_2/RevenueForecast/ReviseForecastMaintenanceReview/ReviseForecastMaintenanceReview")
);


// Receipt Voucher
const ReceiptVoucher = React.lazy(() =>
  import("./components_phase_2/ReceiptVoucher/ReceiptVoucherApplication/ReceiptVoucher")
);
const ReceiptVoucherView = React.lazy(() =>
  import("./components_phase_2/ReceiptVoucher/ReceiptVoucherApplication/ReceiptVoucherView")
);



// Write Off
const WriteOffApplication = React.lazy(() =>
  import("./components_phase_2/WriteOff/WriteOffApplication/WriteOffApplication")
);
const WriteOffReview = React.lazy(() =>
  import("./components_phase_2/WriteOff/WriteOffReview/WriteOffReview")
);


// Arrear Management
const AgingReport = React.lazy(() =>
  import("./components_phase_2/ArrearManagement/AgingReport/AgingReport")
);
const NotificationLevel = React.lazy(() =>
  import("./components_phase_2/ArrearManagement/NotificationLevel/NotificationLevel")
);
const ArrearNotificationDetails = React.lazy(() =>
  import("./components_phase_2/ArrearManagement/ArrearNotificationDetails/ArrearNotificationDetails")
);
const NotificationConfiguration = React.lazy(() =>
  import("./components_phase_2/ArrearManagement/NotificationConfiguration/NotificationConfiguration")
);


//---------------------- PHASE-2 END----------------------//




const AllRoutes = () => {
  const { menu, userInfo } = useSelector((state) => state?.user);
  const [menuList, setMenuList] = useState({});

  useEffect(() => {
    // Dashboard
    const DASHBOARD = menu?.find(
      (menu) => menu.menuCode === 101
    )?.menuResponseDtoList;

    let dashboard = DASHBOARD?.find(
      (menu) => menu?.menuCode === 215
    );
    dashboard = Object.values({ dashboard });

    // Menu Management
    const MENU_MAINTENANCE = menu?.find(
      (menu) => menu.menuCode === 102
    )?.menuResponseDtoList;

    let menuStructure = MENU_MAINTENANCE?.find((menu) => menu?.menuCode === 213);
    menuStructure = Object.values({ menuStructure });

    const menuMaster = MENU_MAINTENANCE?.find(
      (menu) => menu?.menuCode === 214
    )?.subMenuResponseDtoList;

    // Master Management
    const MASTER = menu?.find(
      (menu) => menu.menuCode === 103
    )?.menuResponseDtoList;

    const locationMasters = MASTER?.find(
      (menu) => menu?.menuCode === 201
    )?.subMenuResponseDtoList;

    const otherMasters = MASTER?.find(
      (menu) => menu?.menuCode === 202
    )?.subMenuResponseDtoList;

    const coaMasters = MASTER?.find(
      (menu) => menu?.menuCode === 203
    )?.subMenuResponseDtoList;

    const btcMasters = MASTER?.find(
      (menu) => menu?.menuCode === 230
    )?.subMenuResponseDtoList;

    const agencyMasters = MASTER?.find(
      (menu) => menu?.menuCode === 210
    )?.subMenuResponseDtoList;

    let authConfiguration = MASTER?.find(
      (menu) => menu?.menuCode === 245
    );

    authConfiguration = Object.values({ authConfiguration });

    // console.log("authConfiguration", authConfiguration);

    // Agency Management
    const AGENCY_MANAGEMENT = menu?.find(
      (menu) => menu.menuCode === 105
    )?.menuResponseDtoList;

    const agency = AGENCY_MANAGEMENT?.find(
      (menu) => menu?.menuCode === 207
    )?.subMenuResponseDtoList;

    // COA Mapping
    const COA_MANAGEMENT = menu?.find(
      (menu) => menu.menuCode === 104
    )?.menuResponseDtoList;

    let coaMapping = COA_MANAGEMENT?.find(
      (menu) => menu?.menuCode === 205
    );

    coaMapping = Object.values({ coaMapping });

    const coaApplication = COA_MANAGEMENT?.find(
      (menu) => menu?.menuCode === 204
    )?.subMenuResponseDtoList;

    // Payment Management
    const PAYMENT_MANAGEMENT = menu?.find(
      (menu) => menu.menuCode === 107
    )?.menuResponseDtoList;

    const payment = PAYMENT_MANAGEMENT?.find(
      (menu) => menu?.menuCode === 211
    )?.subMenuResponseDtoList;

    const depositManagement = PAYMENT_MANAGEMENT?.find(
      (menu) => menu?.menuCode === 246
    )?.subMenuResponseDtoList;

    const revenueAccounting = PAYMENT_MANAGEMENT?.find(
      (menu) => menu?.menuCode === 247
    )?.subMenuResponseDtoList;

    // User Management
    const USER_MANAGEMENT = menu?.find(
      (menu) => menu.menuCode === 106
    )?.menuResponseDtoList;

    const user = USER_MANAGEMENT?.find(
      (menu) => menu?.menuCode === 208
    )?.subMenuResponseDtoList;

    // MIS Report
    const MIS_REPORTS = menu?.find(
      (menu) => menu.menuCode === 115
    )?.menuResponseDtoList;

    const reports = MIS_REPORTS?.find(
      (menu) => menu?.menuCode === 209
    )?.subMenuResponseDtoList;

    //---------------------- PHASE-2 START----------------------//
    // Receipt Stock Management
    const RECEIPT_STOCK_MANAGEMENT = menu?.find(
      (menu) => menu.menuCode === 116
    )?.menuResponseDtoList;

    const receiptStock = RECEIPT_STOCK_MANAGEMENT?.find(
      (menu) => menu?.menuCode === 212
    )?.subMenuResponseDtoList;

    const REFUND_AND_DISBURSEMENT = menu?.find(
      (menu) => menu.menuCode === 108
    )?.menuResponseDtoList;

    let vendorRegistration = REFUND_AND_DISBURSEMENT?.find(
      (menu) => menu?.menuCode === 216
    );
    vendorRegistration = Object.values({ vendorRegistration });

    const advancePayment = REFUND_AND_DISBURSEMENT?.find(
      (menu) => menu?.menuCode === 217
    )?.subMenuResponseDtoList;

    const paymentVoucher = REFUND_AND_DISBURSEMENT?.find(
      (menu) => menu?.menuCode === 218
    )?.subMenuResponseDtoList;

    const refund = REFUND_AND_DISBURSEMENT?.find(
      (menu) => menu?.menuCode === 219
    )?.subMenuResponseDtoList;

    const refundAmend = REFUND_AND_DISBURSEMENT?.find(
      (menu) => menu?.menuCode === 223
    )?.subMenuResponseDtoList;

    let rewardBeforeDeposit = REFUND_AND_DISBURSEMENT?.find(
      (menu) => menu?.menuCode === 221
    );
    rewardBeforeDeposit = Object.values({ rewardBeforeDeposit });

    const replenishment = REFUND_AND_DISBURSEMENT?.find(
      (menu) => menu?.menuCode === 222
    )?.subMenuResponseDtoList;

    const paymentVoucherMaintain = REFUND_AND_DISBURSEMENT?.find(
      (menu) => menu?.menuCode === 224
    )?.subMenuResponseDtoList;

    const disbursementApplication = REFUND_AND_DISBURSEMENT?.find(
      (menu) => menu?.menuCode === 220
    )?.subMenuResponseDtoList;

    let withdrawalDeposit = REFUND_AND_DISBURSEMENT?.find(
      (menu) => menu?.menuCode === 228
    );
    withdrawalDeposit = Object.values({ withdrawalDeposit });


    const nettingOff = REFUND_AND_DISBURSEMENT?.find(
      (menu) => menu?.menuCode === 234
    )?.subMenuResponseDtoList;

    // Revenue Forecast
    const REVENUE_FORECAST = menu?.find(
      (menu) => menu.menuCode === 111
    )?.menuResponseDtoList;

    const revenueForecast = REVENUE_FORECAST?.find(
      (menu) => menu?.menuCode === 237
    )?.subMenuResponseDtoList;

    // Receipt Voucher
    const RECEIPT_VOUCHER = menu?.find(
      (menu) => menu.menuCode === 114
    )?.menuResponseDtoList;

    const receiptVoucher = RECEIPT_VOUCHER?.find(
      (menu) => menu?.menuCode === 243
    )?.subMenuResponseDtoList;

    // Arrear Management
    const arrearManagement = menu?.find(
      (menu) => menu.menuCode === 112
    )?.menuResponseDtoList;

    // Write Off
    const WRITE_OFF = menu?.find(
      (menu) => menu.menuCode === 113
    )?.menuResponseDtoList;

    const writeOff = WRITE_OFF?.find(
      (menu) => menu?.menuCode === 244
    )?.subMenuResponseDtoList;

    // ECMS Logs
    const ECMS_LOG = menu?.find(
      (menu) => menu.menuCode === 117
    )?.menuResponseDtoList;

    const logSearch = ECMS_LOG?.find(
      (menu) => menu?.menuCode === 231
    )?.subMenuResponseDtoList;

    // Risk Management
    const RISK_MANAGEMENT = menu?.find(
      (menu) => menu.menuCode === 109
    )?.menuResponseDtoList;

    const riskMasters = RISK_MANAGEMENT?.find(
      (menu) => menu?.menuCode === 235
    )?.subMenuResponseDtoList;

    let riskProfile = RISK_MANAGEMENT?.find(
      (menu) => menu?.menuCode === 248
    );

    riskProfile = Object.values({ riskProfile });

    let riskProfileReport = RISK_MANAGEMENT?.find(
      (menu) => menu?.menuCode === 239
    );
    riskProfileReport = Object.values({ riskProfileReport });

    // Audit Management
    const AUDIT_MANAGEMENT = menu?.find(
      (menu) => menu.menuCode === 110
    )?.menuResponseDtoList;

    const auditManagement = AUDIT_MANAGEMENT?.find(
      (menu) => menu?.menuCode === 238
    )?.subMenuResponseDtoList;

    //---------------------- PHASE-2 END----------------------//


    setMenuList({
      ...menuList,
      dashboard,
      menuStructure,
      menuMaster,
      locationMasters,
      otherMasters,
      coaMasters,
      btcMasters,
      authConfiguration,
      agencyMasters,
      coaApplication,
      agency,
      coaMapping,
      payment,
      depositManagement,
      revenueAccounting,
      user,
      reports,
      receiptStock,
      vendorRegistration,
      advancePayment,
      paymentVoucher,
      refund,
      refundAmend,
      rewardBeforeDeposit,
      replenishment,
      paymentVoucherMaintain,
      disbursementApplication,
      withdrawalDeposit,
      nettingOff,
      arrearManagement,
      revenueForecast,
      receiptVoucher,
      writeOff,
      logSearch,
      riskMasters,
      riskProfile,
      riskProfileReport,
      auditManagement,
    });
    // eslint-disable-next-line
  }, [menu]);

  // for dashboard access
  const boxPermission = {
    agencyRegistration:menuList?.agency?.find(list=> list?.menuCode === 318),
    userRegistration:menuList?.user?.find(list=> list?.menuCode === 321),
    receiptStockDRC:menuList?.receiptStock?.find(list=> list?.menuCode === 346),
    receiptStockRRCO:menuList?.receiptStock?.find(list=> list?.menuCode === 352),
    coaApplication:menuList?.coaApplication?.find(list=> list?.menuCode === 316),
    refundDRC:menuList?.refund?.find(list=> list?.menuCode === 381),
    refundRRCO:menuList?.refund?.find(list=> list?.menuCode === 380),
    failedTransaction:menuList?.revenueAccounting?.find(list=> list?.menuCode === 393),
    nettingOff:menuList?.revenueAccounting?.find(list=> list?.menuCode === 409),
    advancePayment:menuList?.advancePayment?.find(list=> list?.menuCode === 376),
    paymentVoucherMaintain:menuList?.paymentVoucherMaintain?.find(list=> list?.menuCode === 396),
    disbursementApproval:menuList?.disbursementApplication?.find(list=> list?.menuCode === 384),
  };


  // Individual Component Menu Object
  const dashboardComponents = {
    215: Dashboard,
  };

  const menuStructureComponents = {
    213: MenuStructureMaintenance,
  };

  const menuMasterComponents = {
    353: MenuTitle,
    354: MainMenu,
    355: SubMenu,
  };

  const locationMasterComponents = {
    356: RegionalOffice,
    357: Dzongkhag,
    358: Dungkhag,
    359: Gewogs,
    360: Village,
  };

  const otherMasterComponents = {
    361: ModOfPayment,
    372: Country,
    362: CurrencyMaster,
    363: TransactionType,
    302: PrepaymentType,
    303: ExchangeRate,
    370: PaymentCollectionMethod,
    301: SectoralList,
    371: ExternalSystemRegistration,
    364: StatusMaster,
    365: DisbursementType,
    367: TaxTypeMaster,
    368: TaxProcess,
    366: BusinessSector,
    369: Service,
    407: FinancialYears,
    442: BankAccountType,
    478: AuditType,
    479: DisbursementThreshold,
  };

  const btcMastersComponents = {
    397: Edition,
    398: Section,
    399: Chapter,
    400: Heading,
    401: Commodities,
    443: BtcUpload,
  }

  const authConfigurationComponents = {
    245: AuthConfiguration,
  };

  const coaMasterComponents = {
    304: ConfigurationOption,
    305: MajorGroup,
    306: RevenueGroup,
    307: RevenueType,
    308: RevenueSubType,
    309: RevenueGLHead,
    310: RewardPayment,
    311: BankMaster,
    312: BankBranch,
    313: DRCBankAccount,
    314: COAChart,
  };

  const agencyMasterComponents = {
    331: AgencyTypeMaster,
    332: MinistryOrganizationMaster,
    333: DepartmentMaster,
    334: DivisionRegionalOfficeMaster,
  };

  const agencyComponents = {
    317: AgencyRegistration,
    318: AgencyRequestList,
    319: AgencyList,
  };

  const coaApplicationComponents = {
    315: CoaApplicationRequest,
    316: CoaApplicationReview,
  };

  const coaMappingComponents = {
    205: CoaMapping,
  };

  const paymentComponents = {
    335: PaymentAdvice,
    336: CounterPayment,
    338: PaGrouping,
    350: PaGroupingMaintenance,
    374: ReceiptList,
    337: ReceiptMaintenance,
    410: PrepaymentAdviceRequest,
    411: PrepaymentAdviceReview,
  };

  const depositManagementComponents = {
    343: DepositSlip,
    344: DepositMaintenance,
    480: GroupDepositSlip,
    481: GroupDepositSlipMaintenance,
    341: ChequeBounce,
    342: ChequeBounceList,
  };

  const revenueAccountingComponents = {
    339: Reconciliation,
    340: ReconciliationApproval,
    464: ReconciliationBulk,
    482: ReconciliationBulkApproval,
    392: RMAFailedTransactionRequest,
    393: RMAFailedTransactionReview,
    404: ReconciliationMaintenanceApplication,
    405: ReconciliationMaintenanceApproval,
    408: NettingOffRequest,
    409: NettingOffReview,
  };

  const userComponents = {
    320: UserRequestForm,
    321: UserApplicationList,
    322: UserManagement,
    323: ProfileManagement,
    324: RoleManagement,
  };

  const reportsComponents = {
    325: Ledger,
    326: CashBook,
    327: CollectionDetails,
    328: DepositDetails,
    329: ScheduleColDepositStatement,
    330: ReceiptsDepositStatement,
    412: AllAgenciesConsolidatedBankLedger,
    413: BankReconciliationStatement,
    414: DemandCollectionRegister,
    415: ConsolidatedBankLedger,
    416: UserRoleList,
    417: AgencyWiseUser,
    418: ReceiptReversalStatement,
    419: UserStatus,
    420: PrePayDepositStatement,
    421: AgencyWiseCoa,
    422: PrePayDepositAdjustmentStatement,
    423: ChequeDishonoredStatement,
    424: AgencyRegistrationReport,
    425: AssignedRevenueHead,
    426: UserApplicationReport,
    427: SbaCashBook,
    428: BankStatement,
    429: SummeryColDepositStatement,
    440: RMASinglePayment,
    441: RMAGroupPayment,
    472: StockInventoryStatement,
    473: StockRegister,
    474: RefundPaymentStatement,
    475: RewardAfterDepositStatement,
    476: RewardBeforeDepositStatement,
    477: AdvancePaymentStatement,
    483: AdvanceAdjustmentStatement,
    484: AdvanceReimbursementSummary,
    485: ReplenishmentReport,
    489: AdvanceReimbursementStatement,
    490: DisbursementVoucherStatement,
    491: DisbursementVoucherDetails,
    492: DisbursementVoucherCancellationStatement,
    488: AdvanceAdjustmentSummary,
    493: NettingOffStatement,
    494: DelayedDeposit,
    495: WriteOffStatement,
    496: ReceiptReversalFrequency,
    497: Top10Collection,
    498: SectoralReport,
    499: NationalRevenueReport,
    500: MonthlyNationalRevenueReport,
    501: ComparativeReport,
    502: BudgetedTargets,
    503: TaxToGDP,
  }

  //---------------------- PHASE-2 START----------------------//
  const receiptStockComponents = {
    345: DRCReceiptStock,
    346: ReceiptAllocationToRRCO,
    347: RRCOReceiptStock,
    349: AgencyReceiptStock,
    352: ReceiptAllocationToAgencies,
  };

  const vendorRegistrationComponents = {
    216: VendorRegistration,
  };
  const advancePaymentComponents = {
    486: AdvanceAdjustment,
    487: Reimbursement,
    375: AdvancePaymentRequest,
    376: AdvancePaymentReview,
  };
  const paymentVoucherComponents = {
    377: PaymentVoucherRequest,
    378: PaymentVoucherReview,
  };
  const replenishmentComponents = {
    386: ReplenishmentRequest,
    387: ReplenishmentReview,
    388: ReplenishmentApproval,
    406: ReplenishmentDrc,
  }
  const paymentVoucherMaintainComponents = {
    395: PaymentVoucherMaintain,
    396: PaymentVoucherMaintainReview,
  };
  const rewardBeforeDepositComponents = {
    221: RewardBeforeDeposit,
  }
  const refundComponents = {
    379: RefundApplication,
    380: VerifyRefundApplication,
    381: ReviewRefundApplication,
  };
  const refundAmendComponents = {
    389: RefundAmendment,
    390: RefundAmendmentReview,
    391: RefundAmendmentApproval,
  };
  const disbursementApplicationComponents = {
    383: DisbursementVoucher,
    384: DisbursementVoucherApprove,
    385: DisbursementVoucherMaintenance,
    394: UnsuccessfulDisbursement,
  };
  const withdrawalDepositComponents = {
    228: WithdrawalDeposit,
  }
  const arrearComponents = {
    240: NotificationLevel,
    241: NotificationConfiguration,
    242: AgingReport,
  }
  const revenueForecastComponents = {
    444: PreviousYearRevenue,
    445: PreviousYearRevenueReview,
    446: PreviousYearRevenueMaintenanceTab,
    447: PreviousYearRevenueMaintenanceReview,
    448: RevenueForecastApplication,
    449: RevenueForecastApplicationReview,
    450: RevenueForecastMaintenanceTab,
    451: RevenueForecastMaintenanceReview,
    465: ReviseForecastReview,
    466: ReviseForecastMaintenanceTab,
    467: ReviseForecastMaintenanceReview
  }
  const receiptVoucherComponents = {
    468: ReceiptVoucher,
  }
  const writeOffComponents = {
    470: WriteOffApplication,
    471: WriteOffReview,
  }
  const logSearchComponents = {
    402: LogSearchPage,
    403: PaymentAdviceSuccessStatus,
  }
  const riskMasterComponents = {
    430: RiskProfileEntity,
    431: RiskAction,
    432: RiskLevel,
    433: RiskParameterIndicator,
    434: RiskParameterValue,
    435: RiskArea,
    436: RiskSubArea,
    437: RiskLevelThreshold,
    438: RiskRule,
  }
  const riskProfileComponents = {
    248: RiskProfile,
  }
  const riskProfileReportComponents = {
    239: RiskProfileReport,
  }
  const auditManagementComponents = {
    452: AuditPlan,
    453: Auditing,
    459: PrelimAuditReportReview,
    460: PrelimAuditReportApproval,
    461: AuditMaintenance,
    454: AuditResponse,
    455: AuditResponseReview,
    462: FinalAuditReportReview,
    463: FinalAuditReportApprove,
    456: ActionToBeTaken,
    457: AuditReportsDrc,
    458: UpdateLegalAction,
  }

  //---------------------- PHASE-2 END----------------------//


  const dynamicComponents = {
    RevenueGroupList: RevenueGroupList,
    RevenueTypeList: RevenueTypeList,
    RevenueSubTypeList: RevenueSubTypeList,
    RevenueGLHeadList: RevenueGLHeadList,
    AgencyServiceList: AgencyServiceList,
    AgencyRegistrationReview: AgencyRegistrationReview,
    AgencyContactPerson: AgencyContactPerson,
    AgencyRrcoDzongkhag: AgencyRrcoDzongkhag,
    AgencyMaintenanceForm: AgencyMaintenanceForm,
    PaymentAdviceAddPage: PaymentAdviceAddPage,
    PaymentAdviceCounterReceipt: PaymentAdviceCounterReceipt,
    PaymentAdviceViewPage: PaymentAdviceViewPage,
    PaymentAdviceMaintainPage: PaymentAdviceMaintainPage,
    CounterPayment: CounterPayment,
    CounterPaymentReceipt: CounterPaymentReceipt,
    CounterPaymentPreprintedReceipt: CounterPaymentPreprintedReceipt,
    PaGroupingReceipt: PaGroupingReceipt,
    PaGroupingCreate: PaGroupingCreate,
    PaGroupingCancel: PaGroupingCancel,
    PaGroupingUnmap: PaGroupingUnmap,
    DepositSlipCreate: DepositSlipCreate,
    CashDepositSlip: CashDepositSlip,
    BankDepositSlip: BankDepositSlip,
    DepositSlipView: DepositSlipView,
    DepositSlipReprint: DepositSlipReprint,
    DepositSlipUnmap: DepositSlipUnmap,
    DepositSlipUnmapReceipt: DepositSlipUnmapReceipt,
    DepositSlipCancel: DepositSlipCancel,
    ReconciliationView: ReconciliationView,
    ReconciliationReview: ReconciliationReview,
    ReconciliationBulkView: ReconciliationBulkView,
    ReconciliationBulkReview: ReconciliationBulkReview,
    RolePermission: RolePermission,
    UserReviewDecision: UserReviewDecision,
    UserManageAdd: UserManageAdd,
    UserManageEdit: UserManageEdit,
    ReceiptReprint: ReceiptReprint,
    AmendReceipt: AmendReceipt,
    UnmapReceipt: UnmapReceipt,
    ReceiptView: ReceiptView,
    RMAFailedTransactionAddPage: RMAFailedTransactionAddPage,
    AdvancePaymentReceipt: AdvancePaymentReceipt,
    AdvancePaymentReprint: AdvancePaymentReprint,
    AdvancePaymentPrint: AdvancePaymentPrint,
    PaymentVoucherPrint: PaymentVoucherPrint,
    PaymentVoucherReprint: PaymentVoucherReprint,
    ReplenishmentReprint: ReplenishmentReprint,
    ReplenishmentPrint: ReplenishmentPrint,
    ReplenishmentReceiptVoucher: ReplenishmentReceiptVoucher,
    ReplenishmentReceiptVoucherDrc: ReplenishmentReceiptVoucherDrc,
    RefundVoucher: RefundVoucher,
    RewardBeforeDepositVoucher: RewardBeforeDepositVoucher,
    DisbursementVoucherView: DisbursementVoucherView,
    DisbursementVoucherReprint: DisbursementVoucherReprint,
    InitiateDisbursement: InitiateDisbursement,
    ReimbursementVoucher: ReimbursementVoucher,
    WithdrawalAndDepositVoucher: WithdrawalAndDepositVoucher,
    ReceiptVoucherView: ReceiptVoucherView,
    GroupDepositSlipView: GroupDepositSlipView,
    RiskProfileReportDetails: RiskProfileReportDetails,
  };


  // Common Function for all permission
  const generateRoute = (componentObject, permission) => {
    const Component = componentObject[permission?.menuCode]; // set menuCode here permission?.title

    if (Component && (permission?.view || permission?.mainView)) {
      return (
        <Route
          key={permission.title}
          path={permission.path}
          element={<Component permission={permission} />}
        />
      );
    }

    return null;
  };

  // Common Function for dynamic permission
  // By default accessor is using view, but if its under a direct menu add "mainView" from function call
  const generateDynamicRoute = (list, menuCode, componentName, path, accessor = "view") => {
    const permission = list?.find(item => item?.menuCode === menuCode)
    const Component = dynamicComponents[componentName];

    if (Component && (permission?.view || permission?.mainView) && permission?.[accessor]) {
      return (
        <Route
          path={path}
          element={<Component permission={permission} />}
        />
      );
    }

    return null;
  };


  return (
    <>
      <BrowserRouter>
        <React.Suspense fallback={<Loader />}>
          <Routes>
            <Route
              path={`/mainMenu/icon-symbol`}
              element={<IconClassExample />}
            />
            <Route
              path={`/user/createPassword/:username/:token`}
              element={<CreatePassword />}
            />
            <Route path={`/user/forgotPassword`} element={<ForgotPassword />} />

            <Route path={`/`} element={<Auth />}>
              <Route index element={<AuthLogin />} />
              <Route path={`/authentication/login`} element={<AuthLogin />} />
            </Route>

            {/* Payment Gateway */}
            <Route
              path={`/payment-advice/receipt/payment`}
              element={<PaymentPage />}
            />
            {/* ---------------------------- PHASE-2 eCMS Payment START ---------------------------- */}

            <Route
              path={`/payment-advice/receipt/payment/:adviceNo`}
              element={<ECMSPaymentPage />}
            />

            {/* ---------------------------- PHASE-2 eCMS Payment END ---------------------------- */}

            <Route
              path={`/payment-redirect-success/:adviceno/:advicedate/:amount/:benfId/:txnId`}
              element={<PaymentRedirectSuccess />}
            />
            <Route
              path={`/payment-redirect-fail/:adviceno/:advicedate/:amount/:benfId`}
              element={<PaymentRedirectFail />}
            />
            <Route
              path={`/payment-redirect-cancel/:adviceno/:advicedate/:amount/:benfId`}
              element={<PaymentRedirectCancel />}
            />
            <Route
              path={`/payment-receipt/:adviceNo`}
              element={<OnlinePaymentReceipt />}
            />
            <Route
              path={`/payment-group-receipt/:adviceGroupNo`}
              element={<OnlineGPPaymentReceipt />}
            />

            <Route path={`/`} element={<PrivateRoute />}>
              {userInfo?.dateOver ? (
                <Route
                  path={`/user/force-password-change`}
                  element={<ForcePasswordChange />}
                />
              ) : (
                <Route path="/" element={<App />}>
                  <Route>
                    <Route
                      path={`/pages/profile`}
                      element={<Profile />}
                    />
                    <Route
                      path={`/home-dashboard`}
                      element={<Dashboard  permission={boxPermission}/>}
                    />
                    <Route
                      path={`/user/change-password`}
                      element={<ChangePassword />}
                    />
                    <Route
                      path={`/inbox`}
                      element={<InboxList />}
                    />
                    <Route
                      path={`/inbox/:id`}
                      element={<InboxDetails />}
                    />
                    <Route
                      path={`/risk-management/risk-notification`}
                      element={<RiskNotification />}
                    />
                    <Route
                      path={`/arrear-management/notification-details-view`}
                      element={<ArrearNotificationDetails />}
                    />
                    <Route
                      path={`/audit-management/audit-notification`}
                      element={<AuditNotification />}
                    />
                  </Route>

                  {/* Unused routes Start */}
                  <Route
                    path={`/other-masters/disbursement-allowable-master`}
                    element={<DisbursementAllowableMaster />}
                  />
                  <Route
                    path={`/other-masters/target-type`}
                    element={<TargetType />}
                  />
                  <Route
                    path={`/coa-master/COAGroup`}
                    element={<COAGroups />}
                  />
                  <Route
                    path={`/payment/payment-advice/counter-payment-no-split`}
                    element={<CounterPayment />}
                  />
                  <Route
                    path={`/payment/payment-advice/counter-payment-no-split/:paymentAdviceNumber`}
                    element={<CounterPayment />}
                  />
                  {/* <Route
                    path={`/risk-management/assessment`}
                    element={<RiskAssessment />}
                  /> */}
                  {/* Receipt Maintenance Approval */}
                  <Route>
                    <Route
                      path={`/payment/receipt-maintenance-approval-lists`}
                      element={<ReceiptMaintenanceAppList />}
                    />
                    <Route
                      path={`/payment/receipt-maintenance-approval-list/:types`}
                      element={<ReceiptMaintenanceAppList />}
                    />
                    <Route
                      path={`/payment/receipt-maintenance-approval-amend-review/:id`}
                      element={<AmendApproval />}
                    />
                    <Route
                      path={`/payment/receipt-maintenance-approval-unmap-review/:id`}
                      element={<UnmapPaymentApproval />}
                    />
                    <Route
                      path={`/payment/receipt-maintenance-approval-revarsal-review/:id`}
                      element={<ReversalApproval />}
                    />
                  </Route>
                  {/* Unused routes End */}


                  {/* Menu Structure */}
                  {menuList?.menuStructure?.map((permission, i) => (
                    <Route key={i}>
                      {generateRoute(menuStructureComponents, permission)}
                    </Route>
                  ))}

                  {/* COA Application */}
                  {menuList?.dashboard?.map((permission, i) => (
                    <Route key={i}>
                      {generateRoute(dashboardComponents, permission)}
                    </Route>
                  ))}

                  {/* Menu Master */}
                  {menuList?.menuMaster?.map((permission) => (
                    <Route key={permission?.title}>
                      {generateRoute(menuMasterComponents, permission)}
                    </Route>
                  ))}

                  {/* Location Master */}
                  {menuList?.locationMasters?.map((permission) => (
                    <Route key={permission?.title}>
                      {generateRoute(locationMasterComponents, permission)}
                    </Route>
                  ))}

                  {/* Other Master */}
                  {menuList?.otherMasters?.map((permission) => (
                    <Route key={permission?.title}>
                      {generateRoute(otherMasterComponents, permission)}
                    </Route>
                  ))}

                  {/* Others Master Dynamic */}
                  {generateDynamicRoute(
                    menuList?.otherMasters,
                    369,
                    "AgencyServiceList",
                    `/agency-service-list/:id`
                  )}

                  {/* COA Master */}
                  {menuList?.coaMasters?.map((permission) => (
                    <Route key={permission?.title}>
                      {generateRoute(coaMasterComponents, permission)}
                    </Route>
                  ))}

                  {/* COA Master Dynamic */}
                  {generateDynamicRoute(
                    menuList?.coaMasters,
                    306,
                    "RevenueGroupList",
                    `/coa-master/revenue-group-list/:id`
                  )}
                  {generateDynamicRoute(
                    menuList?.coaMasters,
                    307,
                    "RevenueTypeList",
                    `/coa-master/revenue-type-list/:id`
                  )}
                  {generateDynamicRoute(
                    menuList?.coaMasters,
                    308,
                    "RevenueSubTypeList",
                    `/coa-master/revenue-sub-type-list/:id`
                  )}
                  {generateDynamicRoute(
                    menuList?.coaMasters,
                    309,
                    "RevenueGLHeadList",
                    `/coa-master/revenue-gl-head/:id`
                  )}

                  {/* BTC Master */}
                  {menuList?.btcMasters?.map((permission) => (
                    <Route key={permission?.title}>
                      {generateRoute(btcMastersComponents, permission)}
                    </Route>
                  ))}

                  {menuList?.authConfiguration?.map((permission, i) => (
                    <Route key={i}>
                      {generateRoute(authConfigurationComponents, permission)}
                    </Route>
                  ))}

                  {/* COA Application */}
                  {menuList?.coaApplication?.map((permission) => (
                    <Route key={permission?.title}>
                      {generateRoute(coaApplicationComponents, permission)}
                    </Route>
                  ))}

                  {/* Agency Master */}
                  {menuList?.agencyMasters?.map((permission) => (
                    <Route key={permission?.title}>
                      {generateRoute(agencyMasterComponents, permission)}
                    </Route>
                  ))}

                  {/* Agency Maintenance */}
                  {menuList?.agency?.map((permission) => (
                    <Route key={permission?.title}>
                      {generateRoute(agencyComponents, permission)}
                    </Route>
                  ))}

                  {/* Agency Maintenance Dynamic */}
                  {generateDynamicRoute(
                    menuList?.agency,
                    318,
                    "AgencyRegistrationReview",
                    `/agency-registration-application/:id`,
                    "edit"
                  )}
                  {generateDynamicRoute(
                    menuList?.agency,
                    319,
                    "AgencyMaintenanceForm",
                    `/agency-maintenance/:id`,
                    "edit"
                  )}
                  {generateDynamicRoute(
                    menuList?.agency,
                    319,
                    "AgencyContactPerson",
                    `/agency-maintenance/agency-contact-person-details/:id`,
                    "edit"
                  )}
                  {generateDynamicRoute(
                    menuList?.agency,
                    319,
                    "AgencyRrcoDzongkhag",
                    `/agency-maintenance/agency-rrco-dzongkhag/:id`,
                    "edit"
                  )}

                  {/* COA Mapping */}
                  {menuList?.coaMapping?.map((permission, i) => (
                    <Route key={i}>
                      {generateRoute(coaMappingComponents, permission)}
                    </Route>
                  ))}

                  {/* Payment Management */}
                  {menuList?.payment?.map((permission, i) => (
                    <Route key={i}>
                      {generateRoute(paymentComponents, permission)}
                    </Route>
                  ))}

                  {menuList?.depositManagement?.map((permission, i) => (
                    <Route key={i}>
                      {generateRoute(depositManagementComponents, permission)}
                    </Route>
                  ))}

                  {menuList?.revenueAccounting?.map((permission, i) => (
                    <Route key={i}>
                      {generateRoute(revenueAccountingComponents, permission)}
                    </Route>
                  ))}

                  {/* Payment Management Dynamic */}
                  {generateDynamicRoute(
                    menuList?.payment,
                    335,
                    "PaymentAdviceAddPage",
                    `/payment/payment-advice/add`,
                    "add"
                  )}
                  {generateDynamicRoute(
                    menuList?.payment,
                    335,
                    "PaymentAdviceCounterReceipt",
                    `/payment/payment-advice-counter-receipt`,
                  )}
                  {generateDynamicRoute(
                    menuList?.payment,
                    335,
                    "PaymentAdviceViewPage",
                    `/payment/payment-advice/view/:paymentAdviceId`
                  )}
                  {generateDynamicRoute(
                    menuList?.payment,
                    335,
                    "PaymentAdviceMaintainPage",
                    `/payment/payment-advice/maintain/:paymentAdviceId`,
                    "edit"
                  )}

                  {generateDynamicRoute(
                    menuList?.payment,
                    336,
                    "CounterPayment",
                    `/payment/payment-advice/counter-payment/:paymentAdviceNumber`
                  )}
                  {generateDynamicRoute(
                    menuList?.payment,
                    336,
                    "CounterPaymentReceipt",
                    `/payment/payment-advice/counter-payment-receipt`
                  )}
                  {generateDynamicRoute(
                    menuList?.payment,
                    336,
                    "CounterPaymentPreprintedReceipt",
                    `/payment/payment-advice/pre-printed-receipt`
                  )}

                  {generateDynamicRoute(
                    menuList?.payment,
                    338,
                    "PaGroupingCreate",
                    `/payment/pa-grouping-create`,
                    "add"
                  )}
                  {generateDynamicRoute(
                    menuList?.payment,
                    338,
                    "PaGroupingReceipt",
                    `/payment/pa-grouping-receipt`
                  )}

                  {generateDynamicRoute(
                    menuList?.payment,
                    350,
                    "PaGroupingUnmap",
                    `/payment/pa-grouping-maintenance/unmap/:id`,
                    "edit"
                  )}
                  {generateDynamicRoute(
                    menuList?.payment,
                    350,
                    "PaGroupingCancel",
                    `/payment/pa-grouping-maintenance/cancel/:id`,
                    "delete"
                  )}

                  {generateDynamicRoute(
                    menuList?.depositManagement,
                    343,
                    "DepositSlipCreate",
                    `/payment/deposit-slip/create`,
                    "add"
                  )}
                  {generateDynamicRoute(
                    menuList?.depositManagement,
                    343,
                    "CashDepositSlip",
                    `/payment/deposit-slip/cash-deposit-slip`
                  )}
                  {generateDynamicRoute(
                    menuList?.depositManagement,
                    343,
                    "BankDepositSlip",
                    `/payment/deposit-slip/bank-deposit-slip`
                  )}

                  {generateDynamicRoute(
                    menuList?.depositManagement,
                    344,
                    "DepositSlipView",
                    `/payment/deposit-maintenance/deposit-slip-view/:id`
                  )}
                  {generateDynamicRoute(
                    menuList?.depositManagement,
                    344,
                    "DepositSlipReprint",
                    `/payment/deposit-maintenance/deposit-slip-reprint/:id`
                  )}
                  {generateDynamicRoute(
                    menuList?.depositManagement,
                    344,
                    "DepositSlipUnmap",
                    `/payment/deposit-maintenance/deposit-slip-unmap/:id`,
                    "edit"
                  )}
                  {generateDynamicRoute(
                    menuList?.depositManagement,
                    344,
                    "DepositSlipUnmapReceipt",
                    `/payment/deposit-maintenance/deposit-slip-unmap-receipt`,
                    "edit"
                  )}
                  {generateDynamicRoute(
                    menuList?.depositManagement,
                    344,
                    "DepositSlipCancel",
                    `/payment/deposit-maintenance/deposit-slip-cancel/:id`,
                    "delete"
                  )}

                  {generateDynamicRoute(
                    menuList?.revenueAccounting,
                    339,
                    "ReconciliationView",
                    `/payment/reconciliation/view/:reconciliation_staging_id`
                  )}
                  {generateDynamicRoute(
                    menuList?.revenueAccounting,
                    340,
                    "ReconciliationReview",
                    `/payment/reconciliation/review/:id`,
                    "edit"
                  )}

                  {generateDynamicRoute(
                    menuList?.revenueAccounting,
                    464,
                    "ReconciliationBulkView",
                    `/payment/reconciliation-bulk/view/:reconciliationExcelFileInfoId`,
                  )}
                  {generateDynamicRoute(
                    menuList?.revenueAccounting,
                    482,
                    "ReconciliationBulkReview",
                    `/payment/reconciliation-bulk/review/:reconciliationExcelFileInfoId`,
                    "edit"
                  )}

                  {generateDynamicRoute(
                    menuList?.payment,
                    337,
                    "ReceiptReprint",
                    `/payment/receipt-maintenance/reprint/:id`
                  )}
                  {generateDynamicRoute(
                    menuList?.payment,
                    337,
                    "AmendReceipt",
                    `/payment/receipt-maintenance/amend-receipt`,
                    "edit"
                  )}
                  {generateDynamicRoute(
                    menuList?.payment,
                    337,
                    "UnmapReceipt",
                    `/payment/receipt-maintenance/unmap-receipt`,
                    "edit"
                  )}
                  {generateDynamicRoute(
                    menuList?.payment,
                    374,
                    "ReceiptView",
                    `/payment/receipt-list/:id`
                  )}

                  {generateDynamicRoute(
                    menuList?.revenueAccounting,
                    392,
                    "RMAFailedTransactionAddPage",
                    `/payment/rma-failed-transactions/add`,
                    "add"
                  )}
                  {generateDynamicRoute(
                    menuList?.revenueAccounting,
                    392,
                    "ReceiptView",
                    `/payment/rma-failed-transactions/receipt/:id`
                  )}

                  {generateDynamicRoute(
                    menuList?.depositManagement,
                    480,
                    "GroupDepositSlipView",
                    `/group-deposit-slip-application/:id`,
                  )}

                  {/* User */}
                  {menuList?.user?.map((permission, i) => (
                    <Route key={i}>
                      {generateRoute(userComponents, permission)}
                    </Route>
                  ))}

                  {/* User Dynamic */}
                  {generateDynamicRoute(
                    menuList?.user,
                    324,
                    "RolePermission",
                    `/user/role-permission/:id`,
                    "edit"
                  )}
                  {generateDynamicRoute(
                    menuList?.user,
                    321,
                    "UserReviewDecision",
                    `/user/user-management/review/:id`,
                    "edit"
                  )}
                  {generateDynamicRoute(
                    menuList?.user,
                    322,
                    "UserManageAdd",
                    `/user/user-management/add`,
                    "add"
                  )}
                  {generateDynamicRoute(
                    menuList?.user,
                    322,
                    "UserManageEdit",
                    `/user/user-management/edit/:id`,
                    "edit"
                  )}

                  {/* ---------------------------- PHASE-2 START ---------------------------- */}
                  {/* Stock Management */}
                  {menuList?.receiptStock?.map((permission, i) => (
                    <Route key={i}>
                      {generateRoute(receiptStockComponents, permission)}
                    </Route>
                  ))}

                  {/* Refund & Disbursement */}
                  {/* Vendor Registration */}
                  {menuList?.vendorRegistration?.map((permission, i) => (
                    <Route key={i}>
                      {generateRoute(vendorRegistrationComponents, permission)}
                    </Route>
                  ))}

                  {/* Advance Payment */}
                  {menuList?.advancePayment?.map((permission, i) => (
                    <Route key={i}>
                      {generateRoute(advancePaymentComponents, permission)}
                    </Route>
                  ))}

                  {/* Advance Payment Dynamic */}
                  {generateDynamicRoute(
                    menuList?.advancePayment,
                    375,
                    "AdvancePaymentReceipt",
                    `/refund-disbursement/advance-payment-receipt`,
                    "add"
                  )}
                  {generateDynamicRoute(
                    menuList?.advancePayment,
                    375,
                    "AdvancePaymentReprint",
                    `/refund-disbursement/advance-payment-reprint/:id`
                  )}
                  {generateDynamicRoute(
                    menuList?.advancePayment,
                    376,
                    "AdvancePaymentPrint",
                    `/refund-disbursement/advance-payment-print`
                  )}

                  {/* Reimbursement Dynamic */}
                  {generateDynamicRoute(
                    menuList?.advancePayment,
                    487,
                    "ReimbursementVoucher",
                    `/reimbursement/:id`,
                  )}

                  {/* Payment Voucher */}
                  {menuList?.paymentVoucher?.map((permission, i) => (
                    <Route key={i}>
                      {generateRoute(paymentVoucherComponents, permission)}
                    </Route>
                  ))}

                  {/* Payment Voucher Dynamic */}
                  {generateDynamicRoute(
                    menuList?.paymentVoucher,
                    377,
                    "PaymentVoucherPrint",
                    `/refund-disbursement/payment-voucher-print/:paymentType/:id`
                  )}

                  {/* Payment Voucher Maintain */}
                  {menuList?.paymentVoucherMaintain?.map((permission, i) => (
                    <Route key={i}>
                      {generateRoute(
                        paymentVoucherMaintainComponents,
                        permission
                      )}
                    </Route>
                  ))}

                  {/* Payment Voucher Maintain Dynamic */}
                  {generateDynamicRoute(
                    menuList?.paymentVoucherMaintain,
                    395,
                    "PaymentVoucherReprint",
                    `/refund-disbursement/payment-voucher-reprint/:paymentType/:id`
                  )}
                  {generateDynamicRoute(
                    menuList?.paymentVoucherMaintain,
                    395,
                    "ReplenishmentReprint",
                    `/refund-disbursement/replenishment-reprint/:id`
                  )}

                  {/* Refund */}
                  {menuList?.refund?.map((permission, i) => (
                    <Route key={i}>
                      {generateRoute(refundComponents, permission)}
                    </Route>
                  ))}

                  {/* Refund Dynamic */}
                  {generateDynamicRoute(
                    menuList?.refund,
                    379,
                    "RefundVoucher",
                    `/refund-disbursement/refund-voucher/:id`
                  )}

                  {/* Refund Amend */}
                  {menuList?.refundAmend?.map((permission, i) => (
                    <Route key={i}>
                      {generateRoute(refundAmendComponents, permission)}
                    </Route>
                  ))}

                  {/* Replenishment */}
                  {menuList?.replenishment?.map((permission, i) => (
                    <Route key={i}>
                      {generateRoute(replenishmentComponents, permission)}
                    </Route>
                  ))}

                  {/* Replenishment Dynamic */}
                  {generateDynamicRoute(
                    menuList?.replenishment,
                    386,
                    "ReplenishmentPrint",
                    `/refund-disbursement/replenishment-print/:id`
                  )}
                  {generateDynamicRoute(
                    menuList?.replenishment,
                    386,
                    "ReplenishmentReceiptVoucher",
                    `/refund-disbursement/replenishment-receipt-voucher`
                  )}
                  {generateDynamicRoute(
                    menuList?.replenishment,
                    406,
                    "ReplenishmentReceiptVoucherDrc",
                    `/replenishment/replenishment-receipt-voucher-drc`
                  )}

                  {/* Reward Before Deposit */}
                  {menuList?.rewardBeforeDeposit?.map((permission, i) => (
                    <Route key={i}>
                      {generateRoute(rewardBeforeDepositComponents, permission)}
                    </Route>
                  ))}

                  {/* Reward Before Deposit Dynamic */}
                  {generateDynamicRoute(
                    menuList?.rewardBeforeDeposit,
                    221,
                    "RewardBeforeDepositVoucher",
                    `/refund-disbursement/reward-before-deposit-voucher`,
                    "mainView"
                  )}

                  {/* Disbursement Voucher */}
                  {menuList?.disbursementApplication?.map((permission, i) => (
                    <Route key={i}>
                      {generateRoute(
                        disbursementApplicationComponents,
                        permission
                      )}
                    </Route>
                  ))}

                  {/* Disbursement Voucher Dynamic */}
                  {generateDynamicRoute(
                    menuList?.disbursementApplication,
                    383,
                    "DisbursementVoucherView",
                    `/refund-disbursement/disbursement-approved-voucher/:id`
                  )}
                  {generateDynamicRoute(
                    menuList?.disbursementApplication,
                    385,
                    "DisbursementVoucherReprint",
                    `/refund-disbursement/maintenance/disbursement-voucher/:id`
                  )}
                  {generateDynamicRoute(
                    menuList?.disbursementApplication,
                    383,
                    "InitiateDisbursement",
                    `/refund-disbursement/maintenance/disbursement-voucher-initiate/:id`
                  )}





                  {/* Withdrawal Deposit */}
                  {menuList?.withdrawalDeposit?.map((permission, i) => (
                    <Route key={i}>
                      {generateRoute(withdrawalDepositComponents, permission)}
                    </Route>
                  ))}

                  {/* Withdrawal Deposit Dynamic */}
                  {generateDynamicRoute(
                    menuList?.withdrawalDeposit,
                    228,
                    "WithdrawalAndDepositVoucher",
                    `/withdrawal-deposit/:id`,
                    "mainView"
                  )}

                  {/* Netting-Off Dynamic */}
                  {generateDynamicRoute(
                    menuList?.revenueAccounting,
                    408,
                    "ReceiptReprint",
                    `/netting-off-reprint/:id`
                  )}

                  {/* eCMS Payment */}
                  {menuList?.logSearch?.map((permission, i) => (
                    <Route key={i}>
                      {generateRoute(logSearchComponents, permission)}
                    </Route>
                  ))}


                  {/* Risk Management */}
                  {/* Risk Masters */}
                  {menuList?.riskMasters?.map((permission, i) => (
                    <Route key={i}>
                      {generateRoute(riskMasterComponents, permission)}
                    </Route>
                  ))}

                  {menuList?.riskProfile?.map((permission, i) => (
                    <Route key={i}>
                      {generateRoute(riskProfileComponents, permission)}
                    </Route>
                  ))}

                  {/* Risk Profile Report */}
                  {menuList?.riskProfileReport?.map((permission, i) => (
                    <Route key={i}>
                      {generateRoute(riskProfileReportComponents, permission)}
                    </Route>
                  ))}

                  {/* Risk Profile Report Dynamic */}
                  {generateDynamicRoute(
                    menuList?.riskProfileReport,
                    239,
                    "RiskProfileReportDetails",
                    `/risk-management/profile-report/details/:id`,
                    "mainView"
                  )}


                  {/* Audit Management */}
                  {menuList?.auditManagement?.map((permission, i) => (
                    <Route key={i}>
                      {generateRoute(auditManagementComponents, permission)}
                    </Route>
                  ))}

                  {/* plan */}
                  <Route
                    path={`/audit-management/audit-plan-add`}
                    element={<AuditSupport />}
                  />
                  {/* auditing */}
                  <Route
                    path={`/audit-management/audit-report-create/:auditPlanId`}
                    element={<AuditReportCreate />}
                  />
                  <Route
                    path={`/audit-management/audit-report-update/:auditPlanId`}
                    element={<AuditReportUpdate />}
                  />
                  <Route
                    path={`/audit-management/prelim-audit-report-create`}
                    element={<PreliminaryAuditReportCreate />}
                  />
                  <Route
                    path={`/audit-management/prelim-audit-report-generate/:preliminaryAuditReportId`}
                    element={<PreliminaryAuditReportGenerate />}
                  />
                  {/* prelim review */}
                  <Route
                    path={`/audit-management/prelim-audit-report/review-decision/:preliminaryAuditReportId`}
                    element={<PrelimAuditReportReviewDecision />}
                  />
                  {/* prelim approve */}
                  <Route
                    path={`/audit-management/prelim-audit-report/approve-decision/:preliminaryAuditReportId`}
                    element={<PrelimAuditReportApprovalDecision />}
                  />
                  {/* audit maintain */}
                  <Route
                    path={`/audit-management/audit-report-maintain/:auditPlanId`}
                    element={<AuditReportMaintain />}
                  />
                  <Route
                    path={`/audit-management/prelim-audit-report/maintain`}
                    element={<PreliminaryAuditReportMaintain />}
                  />
                  {/* Audit Response */}
                  <Route
                    path={`/audit-management/audit-response/report-view/:auditDetailsId`}
                    element={<AuditResponseReportView />}
                  />
                  <Route
                    path={`/audit-management/audit-update-response/:auditDetailsId/:id`}
                    element={<AuditResponseUpdate />}
                  />
                  {/* Audit Response Review */}
                  <Route
                    path={`/audit-management/audit-response-review/preliminary-report/:auditDetailsId`}
                    element={<AuditResponseReviewPreliminaryReport />}
                  />
                  <Route
                    path={`/audit-management/final-audit-report/generate/:auditDetailsId`}
                    element={<FinalAuditReportGenerate />}
                  />
                  <Route
                    path={`/audit-management/final-audit-report/before-atr/:auditDetailsId`}
                    element={<FinalAuditReportBeforeATR />}
                  />
                  <Route
                    path={`/audit-management/audit-response-review-details/:auditPlanId`}
                    element={<AuditResponseReviewDetails />}
                  />
                  {/* Final Audit Report Review */}
                  <Route
                    path={`/audit-management/final-audit-report/review-decision/:auditDetailsId`}
                    element={<FinalAuditReportReviewDecision />}
                  />
                  {/* Final Audit Report Approval */}
                  <Route
                    path={`/audit-management/final-audit-report/approve-decision/:auditDetailsId`}
                    element={<FinalAuditReportApproveDecision />}
                  />
                  <Route
                    path={`/audit-management/final-audit-report/after-atr/:auditDetailsId`}
                    element={<FinalAuditReportAfterATR />}
                  />
                  {/* Action To Be Taken */}
                  <Route
                    path={`/audit-management/action-to-be-taken/observation-recommendation/:id`}
                    element={<ObservationsRecommendations />}
                  />
                  {/* Audit Reports DRC */}
                  <Route
                    path={`/audit-management/audit-reports-drc/observation-recommendation/:id`}
                    element={<AuditReportsDrcObservations />}
                  />
                  {/* Legal Action */}
                  <Route
                    path={`/audit-management/legal-action/observation-recommendation/:id`}
                    element={<LegalActionObservations />}
                  />


                  {/* Revenue Forecasting */}
                  {menuList?.revenueForecast?.map((permission, i) => (
                    <Route key={i}>
                      {generateRoute(revenueForecastComponents, permission)}
                    </Route>
                  ))}


                  {/* Arrear Management */}
                  {menuList?.arrearManagement?.map((permission, i) => (
                    <Route key={i}>
                      {generateRoute(arrearComponents, permission)}
                    </Route>
                  ))}


                  {/* Receipt Voucher  */}
                  {menuList?.receiptVoucher?.map((permission, i) => (
                    <Route key={i}>
                      {generateRoute(receiptVoucherComponents, permission)}
                    </Route>
                  ))}

                  {/* Receipt Voucher Dynamic */}
                  {generateDynamicRoute(
                    menuList?.receiptVoucher,
                    468,
                    "ReceiptVoucherView",
                    `/receipt-voucher/:id`
                  )}


                  {/* Write Off */}
                  {menuList?.writeOff?.map((permission, i) => (
                    <Route key={i}>
                      {generateRoute(writeOffComponents, permission)}
                    </Route>
                  ))}


                  {/* ---------------------------- PHASE-2 END ---------------------------- */}


                  {/* Report */}
                  {menuList?.reports?.map((permission, i) => (
                    <Route key={i}>
                      {generateRoute(reportsComponents, permission)}
                    </Route>
                  ))}
                  
                </Route>
              )}
            </Route>

            <Route path={`/`} element={<CustomPages />}>
              <Route path="*" element={<Error404 />} />
            </Route>
          </Routes>
        </React.Suspense>
      </BrowserRouter>
    </>
  );
};

export default AllRoutes;
